<template>
  <mly-login-register :is-login="false" @confirm="handleConfirm">
    <div class="input-group">
      <input
        type="text"
        class="login-register-input"
        maxlength="20"
        placeholder="请输入注册的帐号"
        v-model="username"
      >
      <div class="tip">
        <span>{{prompt.username}}</span>
      </div>
    </div>
    <div class="input-group">
      <input
        type="text"
        class="login-register-input"
        maxlength="20"
        placeholder="请输入密码"
        v-model="pwd"
      >
      <div class="tip">
        <span>{{prompt.pwd}}</span>
      </div>
      <div
        class="icon"
        :class="{'eye-open':showPassword,'eye-close':!showPassword}"
        @click="handleChange"
      ></div>
    </div>
    <div class="input-group">
      <input
        type="text"
        class="login-register-input"
        maxlength="20"
        placeholder="请再次输入密码"
        v-model="pwd2"
      >
      <div class="tip">
        <span>{{prompt.pwd2}}</span>
      </div>
      <div
        class="icon"
        :class="{'eye-open':showPassword,'eye-close':!showPassword}"
        @click="handleChange"
      ></div>
    </div>
  </mly-login-register>
</template>

<script>
import axios from "axios";
import Const from "../../commons/const.js";
import util from '../../commons/util.js'
import mlyLoginRegister from "./mly-login-register";

export default {
  components: {
    mlyLoginRegister
  },
  data() {
    return {
      showPassword: false,
      usernameRegex: /^(?![0-9]*$)[a-zA-Z0-9]{6,15}$/,
      username: "",
      pwd: "",
      pwd2: "",
      prompt: {
        username: "",
        pwd: "",
        pwd2: ""
      }
    };
  },
  methods: {
    handleChange() {
      this.showPassword = !this.showPassword;
    },
    handleConfirm() {
      if (
        this.username.trim() == "" ||
        this.pwd.trim() == "" ||
        this.pwd2.trim() == ""
      ) {
        return;
      }
      if (!this.usernameRegex.test(this.username)) {
        this.prompt.username = "6-16位字母、数字,不能为纯数字";
        return;
      } else {
        this.prompt.username = "";
      }
      if (this.pwd.length < 6 || this.pwd.length > 16) {
        this.prompt.pwd = "密码长度6-16个字符";
        return;
      } else {
        this.prompt.pwd = "";
      }
      if (this.pwd != this.pwd2) {
        this.prompt.pwd2 = "两次输入的密码不一致";
        return;
      } else {
        this.prompt.pwd2 = "";
      }
      axios
        .get("/user/register", {
          params: {
            username: this.username,
            pwd: this.pwd
          }
        })
        .then(response => {
          if (response.returnCode == Const.RequestSuccess) {
            util.toast("注册成功");
            setTimeout(() => {
              this.$router.push("user");
            }, 1000);
          } else {
            util.toast(response.returnMsg);
          }
        });
    }
  }
};
</script>
<style>
</style>
