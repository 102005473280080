<template>
  <div :class="$style.wrap">
    <header>
      <router-link :to="turnLeft">
        <img src="@/assets/icon_left_arrow.png" alt>
      </router-link>
      <h1>{{promptText}}</h1>
      <router-link to="./register" class="a-label-reset" style="color:white" v-if="isLogin">注册</router-link>
      <a
        href="javascript:void(0)"
        class="a-label-reset"
        style="color: #20A2DC;visibility: hidden;"
        v-else
      >占位</a>
    </header>

    <section :class="$style.logo">
      <img src="@/assets/mly_logo.png" class="img-responsive" alt>
    </section>

    <section :class="$style.inputRegion">
      <slot></slot>
    </section>

    <section :class="$style.confirm">
      <button type="button" @click="$emit('confirm')">{{promptText}}</button>
    </section>
  </div>
</template>
<script>
export default {
  name: "mlyLoginRegister",
  props: {
    // 用于判断当前渲染的是登录还是注册页面，默认是登录
    isLogin: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    promptText() {
      return this.isLogin ? "登录" : "注册";
    },
    turnLeft() {
      return this.isLogin ? "./user" : "./login";
    }
  }
};
</script>
<style module>
.wrap header {
  align-items: center;
  padding: 0.32rem 0.25rem;
  background-color: #20a2dc;
  display: flex;
  justify-content: space-between;
}

.wrap header a {
  font-size: 0.3rem;
  width: 0.6rem;
}

.wrap header img {
  width: 0.17rem;
  height: 0.3rem;
  display: block;
}

.wrap header h1 {
  margin: 0;
  font-size: 0.4166rem;
  font-weight: 400;
  color: white;
}

.logo img {
  margin: 1rem auto;
  width: 2.65rem;
  height: 1.65rem;
}

.input-region {
  padding: 0 0.25rem;
}

.confirm {
  margin: 0 0.25rem;
  margin-top: 0.3rem;
}

.confirm button {
  color: white;
  width: 100%;
  height: 0.92rem;
  font-size: 0.354rem;
  background-color: #20a2dc;
  border-radius: 0.05rem;
  border: none;
}
</style>
<style src="./login-register.css"></style>
